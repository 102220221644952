function onPlaceChanged() {

  $('#check_home').removeAttr('checked');
  var place = this.getPlace();

  $("#latitude").val(place.geometry.location.lat());
  $("#longitude").val(place.geometry.location.lng());
}
function initializeAutocomplete(id) {
  var element = document.getElementById(id);
  if (element) {
    var autocomplete = new google.maps.places.Autocomplete(element, { types: ['(regions)'] });
    google.maps.event.addListener(autocomplete, 'place_changed', onPlaceChanged);
  }
}
function onPlaceChangedFull() {
  var place = this.getPlace();

  console.log(place);
  for (var i = 0; i < place.address_components.length; i++) {
    console.log(place.address_components[i]);
    
    if (place.address_components[i].types[0] == "locality"){
      $("#city").val(place.address_components[i].long_name);
    }
    else if (place.address_components[i].types[0] == "country"){
      $("#country").val(place.address_components[i].long_name);
    }
    else if (place.address_components[i].types[0] == "postal_code"){
      $("#postal_code").val(place.address_components[i].long_name);
    }
    else if (place.address_components[i].types[0] == "route"){
      $("#street_label").val(place.address_components[i].long_name);
    }
    else if (place.address_components[i].types[0] == "street_number"){
      $("#street_num").val(place.address_components[i].long_name);
    }

  }
  $("#gmap_place_id").val(place.place_id);
  $("#my_lat").val(place.geometry.location.lat());
  $("#my_long").val(place.geometry.location.lng());
}
function initializeAutocompleteFull(id) {
  var element = document.getElementById(id);
  if (element) {
    var autocomplete = new google.maps.places.Autocomplete(element);
    google.maps.event.addListener(autocomplete, 'place_changed', onPlaceChangedFull);
  }
}
function loadCurrentPosition() {  
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(function(position) {
      var pos = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };

      $('#latitude').val(position.coords.latitude);
      $('#longitude').val(position.coords.longitude);  

      $('#check_home').attr('checked', 'checked');
    }, function() {
      console.log("Recup position OK");
    });
  } else {
    console.log("Recup position NOK");
  }
}
function switchToElement(elem){
  $("#game_details_gallery").css("display","none");
  $("#game_details_map").css("display","none");
  $("#game_details_tchat").css("display","none");
  $("#game_details_registered").css("display","none");

  $("#game_details_main_zone_right").removeClass("bk_white");
  $("#game_details_main_zone_right").removeClass("bk_red");
  $("#game_details_main_zone_right").removeClass("bk_yellow");
  $("#game_details_main_zone_right").removeClass("bk_bluelight");

  if (elem == 'gallery'){
      $("#game_details_gallery").css("display","block");
      $("#game_details_main_zone_right").addClass("bk_white");
  }
  else if (elem == 'map'){
      $("#game_details_map").css("display","block");
      $("#game_details_main_zone_right").addClass("bk_red");
  }
  else if (elem == 'tchat'){
      $("#game_details_tchat").css("display","block");
      $("#game_details_main_zone_right").addClass("bk_yellow");
  }
  else if (elem == 'registered'){
      $("#game_details_registered").css("display","block");
      $("#game_details_main_zone_right").addClass("bk_bluelight");
  }
}
function switchStateRegistration(id_game){
  console.log("switchStateRegistration");
  var btn = $('#reg_game_'+id_game);
  type_btn = btn.attr('itemprop');

  var methode = "DELETE";
  if (type_btn == "reg_button") methode = "POST";


  $.ajax({
    method: methode,
    url: "/registrate/"+id_game, 
    
    success: function(result){        
        if (type_btn == "reg_button"){
          if (result.registration_state){
            $(btn).html('SE DESINSCRIRE');
            $(btn).attr('itemprop','unreg_button');
          }
          else{
            $(btn).html('EN ATTENTE');
            $(btn).attr('itemprop','waitreg_button');
          }

          $('#nb_play_'+id_game).html(result.nb_registrations);
        }
        else{
          $(btn).html('S\'INSCRIRE');
          $(btn).attr('itemprop','reg_button');
        }

        $('#nb_play_'+id_game).html(result.nb_registrations);
        showNotif("Inscription à une partie", result.message);
      }
  });  
}
function showNotif(title, body){
  $('#titleNotif').html(title);
  $('#bodyNotif').html(body);

  $(".toast").toast({
      delay: 3000
  });
  $(".toast").toast('show');

}
function preloadImgFile(input){
  if (input.files && input.files[0]) {
      $('#previewFile').html("");
      $(input.files).each(function(i){
        var reader = new FileReader();
        reader.onload = function (e) {
            if ($('#previewFile').attr('withchange') == "false"){
              $('#previewFile').append('<img src="'+e.target.result+'" style="max-height: 150px;" class="img-thumbnail" />');
            } 

            else{
              $('#previewFile').html('<img src="'+e.target.result+'" style="max-height: 200px;cursor:pointer;" title="Changer votre avatar" class="img-thumbnail" onclick="document.getElementById(\'user_form_avatar\').click();"/>');
            }
        }
        reader.readAsDataURL(input.files[i]);
      });
      
      $('#previewFile').css('display','block');
      $('#inputFile').css('display','none');
  }
}

function switchStateBudz(targetBudzId, mode){
  if (mode == 'add'){
    $.ajax({
      method: "POST",
      url: "/budz/"+targetBudzId, 
      
      success: function(result){ 
        showNotif("Ajout d'un budz", result.message);
      }
    });  
  }
}

function switchConfirmRegistration(id_registration){
  $.ajax({
    method: "PUT",
    url: "/registrate/"+id_registration+"/confirm", 
    
    success: function(result){ 
      showNotif("Inscription à une partie", result.message);
    }
  });  

  $('#confirmReg_'+id_registration).css('display','none');
  $('#registration_'+id_registration).removeAttr('style');
}

function loadCalendar(){
  document.addEventListener('DOMContentLoaded', () => {
    var calendarEl = document.getElementById('calendar');

    var calendar = new FullCalendar.Calendar(calendarEl, {
        defaultView: 'dayGridMonth',
        editable: false,
        eventSources: [
            {
                url: "/user/registrations",
                method: "POST",
                extraParams: {
                    filters: JSON.stringify({})
                },
                failure: () => {
                    // alert("There was an error while fetching FullCalendar!");
                },
            },
        ],
        header: {
            left: 'prev,next',
            center: 'title'
        },
        plugins: [ 'interaction', 'dayGrid', 'timeGrid' ], // https://fullcalendar.io/docs/plugin-index
        timeZone: 'local',
        locale: 'fr',
        eventLimit: true, // allow "more" link when too many events
        height: 'parent',
        firstDay : 1
    });
    calendar.render();
  });
}


function deleteGame(idGame){
    $.ajax({
        method: "DELETE",
        url: "/game/"+idGame,

        success: function(result){
            showNotif("Suppression d'une partie", result.message);
        }
    });
}
/* ADMINISTRATION */
function initializeAdminGameTypes(){
    $('#addGTForm').submit(function(event) {

        $('.form-group').removeClass('has-error'); // remove the error class
        $('.help-block').remove(); // remove the error text

        var form = $('#addGTForm').get(0);
        var formData = new FormData(form);// get the form data

        $.ajax({
            type    : 'POST', // define the type of HTTP verb we want to use (POST for our form)
            url     : '/gametypes', // the url where we want to POST
            data    : formData, // our data object
            dataType  : 'json', // what type of data do we expect back from the server
            encode    : true,
            processData: false,
            contentType: false,
            statusCode: {
                201: function () {
                    document.location.reload();
                }
            }
        })
        event.preventDefault();
    });
}

function initializeAdminBudzpoints(){
    $('#addBudzEvent').submit(function(event) {
        $('.form-group').removeClass('has-error'); // remove the error class
        $('.help-block').remove(); // remove the error text

        var form = $('#addBudzEvent').get(0);
        var formData = new FormData(form);// get the form data

        $.ajax({
            type    : 'POST', // define the type of HTTP verb we want to use (POST for our form)
            url     : '/budzpoints_events', // the url where we want to POST
            data    : formData, // our data object
            dataType  : 'json', // what type of data do we expect back from the server
            encode    : true,
            processData: false,
            contentType: false,
            statusCode: {
                201: function () {
                    document.location.reload();
                }
            }
        })
        event.preventDefault();
    });

    $('#addBudzRank').submit(function(event) {
        $('.form-group').removeClass('has-error'); // remove the error class
        $('.help-block').remove(); // remove the error text

        var form = $('#addBudzRank').get(0);
        var formData = new FormData(form);// get the form data

        $.ajax({
            type    : 'POST', // define the type of HTTP verb we want to use (POST for our form)
            url     : '/budzpoints_ranks', // the url where we want to POST
            data    : formData, // our data object
            dataType  : 'json', // what type of data do we expect back from the server
            encode    : true,
            processData: false,
            contentType: false,
            statusCode: {
                201: function () { //Employee_Company saved now updated
                    document.location.reload();
                }
            }
        })
        event.preventDefault();
    });
}
function initializeAdminLogCode(){
    $('#addLogCode').submit(function(event) {

        $('.form-group').removeClass('has-error'); // remove the error class
        $('.help-block').remove(); // remove the error text

        var form = $('#addLogCode').get(0);
        var formData = new FormData(form);// get the form data

        $.ajax({
            type    : 'POST', // define the type of HTTP verb we want to use (POST for our form)
            url     : '/logcode', // the url where we want to POST
            data    : formData, // our data object
            dataType  : 'json', // what type of data do we expect back from the server
            encode    : true,
            processData: false,
            contentType: false,
            statusCode: {
                201: function () {
                    document.location.reload();
                }
            }
        })
        event.preventDefault();
    });
}

function addNewGametype(){
  $('#modalAddGT').modal('show');  
}

function addNewBudzpointEvent(){
  $('#modalAddBudzEvent').modal('show');  
}

function addNewBudzpointRank(){
  $('#modalAddBudzRank').modal('show');  
}

function addNewLogCode(){
    $('#modalAddLogCode').modal('show');
}

/*****************/
function checkNotifications(){
  $.getJSON('/cptnotifs', function (data) {
      $('#cptNewFriendsRequests').html(data.budzRequests);
      $('#cptNewPrivateMessages').html(data.privates_msg);
      $('#cptNewNotifications').html(data.notifications);

      if (data.budzRequests == 0) $('#cptNewFriendsRequests').css('display','none');
      else $('#cptNewFriendsRequests').css('display','');
      if (data.privates_msg == 0) $('#cptNewPrivateMessages').css('display','none');
      else $('#cptNewPrivateMessages').css('display','');
      if (data.notifications == 0) $('#cptNewNotifications').css('display','none');
      else $('#cptNewNotifications').css('display','');
  });
}

function confirmBudzRequest(idRequest){
  $('#btnGroupBudzRequest_'+idRequest).css('display','none');
  $.ajax({
    method: "PUT",
    url: "/budz/"+idRequest+"/confirm", 
    
    success: function(result){ 
      $('[data-id="friendsRequest"]').popover("dispose");
      checkNotifications();
    }
  });  
}



/* MESSAGERIE */
function loadMsgFunctions(){
  $('#newSendForm').submit(function(event) {

      $('.form-group').removeClass('has-error'); // remove the error class
      $('.help-block').remove(); // remove the error text

      var form = $('#newSendForm').get(0);
      var formData = new FormData(form);// get the form data

      $.ajax({
        type    : 'POST', // define the type of HTTP verb we want to use (POST for our form)
        url     : '/messagerie/new', // the url where we want to POST
        data    : formData, // our data object
        dataType  : 'json', // what type of data do we expect back from the server
        encode    : true,
        processData: false,
        contentType: false,
        statusCode: {
              201: function () { //Employee_Company saved now updated
                  showNotif("Message privé", "Message bien envoyé !");
              }
          }
      })
      event.preventDefault();
    });
  $( "#dest_user" )
      // don't navigate away from the field on tab when selecting an item
      .on( "keydown", function( event ) {
        if ( event.keyCode === $.ui.keyCode.TAB &&
            $( this ).autocomplete( "instance" ).menu.active ) {
          event.preventDefault();
        }
      })
      .autocomplete({
        source: function( request, response ) {
          console.log("source");
          $.getJSON( "/users/search", {
            term: request.term
          }, response );
        },
        search: function() {
          // custom minLength
          var term = this.value;
          if ( term.length < 2 ) {
            return false;
          }
        },
        focus: function() {
          console.log("focus");
          // prevent value inserted on focus
          return false;
        },
        select: function( event, ui ) {
          var terms =  this.value;
          // remove the current input
          //terms.pop();
          // add the selected item
          //terms.push( ui.item.value );
          // add placeholder to get the comma-and-space at the end
          /*terms.push( "" );
          this.value = terms.join( ", " );*/
          this.value = ui.item.value;
          $( "#dest_user_id" ).val(ui.item.id);
          return false;
        }
      });
}

function replyMsg(){
  var idMsgReply = $('#currentMsgId').val();
  $('#modalViewPrivateMessage').modal('hide');

  $.getJSON('/messagerie/'+idMsgReply, function (data) {
      $('#dest_user_id').val(data.user_from);
      $('#dest_user').val(data.user_from);
      $('#object').val("Re: "+data.object);

      $('#tab_new').click();
  });
}

function showPrivateMessage(id_message){
  $('#titlePrivateMsg').html("");
  $('#contentPrivateMsg').html("");
  $('#footerPrivateMsg').html("");
  $('#currentMsgId').val("");

  $.getJSON('/messagerie/'+id_message, function (data) {
      $('#msgLine_'+id_message).css('font-weight', 'normal')
      $('#titlePrivateMsg').html(data.object);
      $('#contentPrivateMsg').html(data.content);
      $('#currentMsgId').val(data.id);
      $('#footerPrivateMsg').html("Message de "+data.user_from+", reçu le "+data.date);

      $('#modalViewPrivateMessage').modal('show');
  });
}

/* Tchat de parties */
function loadNewGameTchats(idGame, lastIdMsg){
  console.log("loadNewGameTchats "+idGame+" / "+lastIdMsg);
  $.getJSON('/game/' + idGame + '/tchat/'+lastIdMsg, function (data) {
      console.log(data);
  });
}

function sendTchatMessage(){
    console.log("Nouveau message tchat");
}
/**************/

window.onPlaceChanged = onPlaceChanged;
window.initializeAutocomplete = initializeAutocomplete;
window.onPlaceChangedFull = onPlaceChangedFull;
window.initializeAutocompleteFull = initializeAutocompleteFull;
window.loadCurrentPosition = loadCurrentPosition;
window.switchToElement = switchToElement;
window.switchStateRegistration = switchStateRegistration;
window.showNotif = showNotif;
window.preloadImgFile = preloadImgFile;
window.deleteGame = deleteGame;
window.switchStateBudz = switchStateBudz;
window.switchConfirmRegistration = switchConfirmRegistration;
window.loadCalendar = loadCalendar;
window.initializeAdminGameTypes = initializeAdminGameTypes;
window.initializeAdminBudzpoints = initializeAdminBudzpoints;
window.initializeAdminLogCode = initializeAdminLogCode;
window.addNewGametype = addNewGametype;
window.addNewBudzpointEvent = addNewBudzpointEvent;
window.addNewBudzpointRank = addNewBudzpointRank;
window.addNewLogCode = addNewLogCode;
window.checkNotifications = checkNotifications;
window.confirmBudzRequest = confirmBudzRequest;
window.loadMsgFunctions = loadMsgFunctions;
window.showPrivateMessage = showPrivateMessage;
window.replyMsg = replyMsg;
window.loadNewGameTchats = loadNewGameTchats;
window.sendTchatMessage = sendTchatMessage;


$(function () {
 
  $('[data-id="friendsRequest"]').click(function() {
    if($('[data-id="friendsRequest"]').data('bs.popover')){
      $('[data-id="friendsRequest"]').popover("dispose");
    }
    else{
      $('[data-id="friendsRequest"]').popover({
        content : "Chargement ...",
        placement: 'bottom',
        trigger: 'manual'
      });
      $('[data-id="friendsRequest"]').popover("show");
      $.getJSON('/budz/pending', function (data) {
        $('[data-id="friendsRequest"]').popover("dispose");
        $('[data-id="friendsRequest"]').popover({
            trigger: 'manual',
            html : true,
            placement: 'bottom',
            content: function() {
              var content = '<ul class="list-group">';
              console.log(content);
              $.each( data.list_budz_request, function( key, val ) {
                if (val.from.avatar){
                  content += '<li class="list-group-item"><div class="media"><img class="align-self-start mr-3 w-25" src="../uploads/images/'+val.from.avatar.filename+'" alt="Generic placeholder image"><div class="media-body"><p class="mt-0">'+val.from.firstname+' '+val.from.lastname+' ('+val.from.username+')</p><div class="btn-group btn-group-sm" role="group" aria-label="'+val.id_budz_request+'" id="btnGroupBudzRequest_'+val.id_budz_request+'"><a type="button" class="btn btn-primary text-white btnConfirmBudzRequest" >Confirmer</a><a type="button" class="btn btn-light">Supprimer</a></div></div></div></li>';
                }
                else {
                  content += '<li class="list-group-item"><div class="media"><img class="align-self-start mr-3 w-25" src="../build/images/logo.8347914c.png" alt="Generic placeholder image"><div class="media-body"><p class="mt-0">'+val.from.firstname+' '+val.from.lastname+' ('+val.from.username+')</p><div class="btn-group btn-group-sm" role="group" aria-label="'+val.id_budz_request+'" id="btnGroupBudzRequest_'+val.id_budz_request+'"><a type="button" class="btn btn-primary text-white btnConfirmBudzRequest" >Confirmer</a><a type="button" class="btn btn-light">Supprimer</a></div></div></div></li>';
                }
              });
              if (data.list_budz_request.length == 0) content += '<li class="list-group-item">Aucune nouvelle demande</li>';

              content += '</ul>';
               return content;
            }
        });
        
        $('[data-id="friendsRequest"]').popover("show");
        $(".btnConfirmBudzRequest").each(function() {
          console.log($(this).parent().attr("aria-label"));
          $( this ).click( function(){
            confirmBudzRequest($( this ).parent().attr("aria-label"));
          });
        })
      })
    }
  });

  $( "#quicksearch" ).keyup(function(e) {
    if(e.keyCode == 13)
    {
      if($( "#quicksearch" ).data('bs.popover')){        
        $( "#quicksearch" ).popover("dispose");
      }      
      $( "#quicksearch" ).popover({
        content : "Chargement ...",
        placement: 'bottom',
        trigger: 'focus'
      });
      $( "#quicksearch" ).popover("show");
      $.getJSON('/quicksearch', {search : $( "#quicksearch" ).val()}, function (data) {
        $( "#quicksearch" ).popover("dispose");
        $( "#quicksearch" ).popover({
            trigger: 'click focus',
            html : true,
            placement: 'bottom',
            content: function() {
              var content = '<ul class="list-group">';
              $.each( data.listUsers, function( key, val ) {
                if (val.avatar){
                  content += '<li class="list-group-item"><div class="media"><img class="align-self-start mr-3 w-25" src="../uploads/images/'+val.avatar.filename+'" alt="Generic placeholder image"><div class="media-body"><p class="mt-0">'+val.firstname+' '+val.lastname+' ('+val.username+')</p></div></div></li>';
                }
                else {
                  content += '<li class="list-group-item"><div class="media"><img class="align-self-start mr-3 w-25" src="../build/images/logo.8347914c.png" alt="Generic placeholder image"><div class="media-body"><p class="mt-0">'+val.firstname+' '+val.lastname+' ('+val.username+')</p></div></div></li>';
                }
              });
              if (data.listUsers.length == 0) content += '<li class="list-group-item">Aucun résultat</li>';
              content += '<li class="list-group-item"><a href="/searchgame?search='+$( "#quicksearch" ).val()+'">Chercher des parties</a></li>';

              content += '</ul>';
               return content;
            }
        });
       $( "#quicksearch" ).popover("show");
      })
    }
    
  });

})